<template>
  <CardToolbox>
    <SearchHeaderStyle>
      <sdPageHeader title="Weight">

      </sdPageHeader>
    </SearchHeaderStyle>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Filters ">
          <a-form layout="vertical">
            <a-row :gutter="25">
              <a-col :span="6">
                <a-form-item label="Size" name="sizeId">
                  <a-input v-model:value="searchValue" placeholder="Search by Name" />
                  <template #suffix>
                  </template>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Size" name="sizeId">
                  <a-select v-model:value="searchSizeId" placeholder="Size" show-search :filter-option="customFilter">
                    <a-select-option v-for="(size, index) in SizeApi" :key="index" :value="size.id">
                      {{ size.name }} - {{ size.mm }}mm</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6" class="button-container">
                <a-button :style="{ width: '100%' }" class="btn ant-btn-lg client-form-submit" outlined type="primary"
                  @click="onHandleSearch()">
                  <span>Filter</span>
                </a-button>
              </a-col>
              <a-col :span="6" class="button-container">
                <a-button :style="{ width: '100%' }" class="ant-btn-lg" @click="clearFilters()" danger>
                  <span>Clear Filters</span>
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </sdCards>
      </a-col>
    </a-row>
    <a-row :gutter="15">
      <a-col class="w-100" :md="14">
        <sdCards title="Weight">
          <div v-if="isLoading" class="spin">
            <a-spin />
          </div>

          <div v-else>
            <TableWrapper class="table-data-view table-responsive">
              <a-table @change="onHandleTableChange" :pagination="pagination" :dataSource="dataSource"
                :columns="columns" />
            </TableWrapper>
          </div>
        </sdCards>
      </a-col>
      <a-col class="w-100" :md="10">
        <sdCards :title="HeaderInfo">
          <FormValidationWrap>
            <VerticalFormStyleWrap>
              <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState" :rules="rules"
                :layout="formState.layout">
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="name" name="name" label="Weight">
                      <a-input v-model:value="formState.name" placeholder="Weight" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="sizeId" label="Size" name="sizeId">
                      <a-select v-if="SizeApi.length" v-model:value="formState.sizeId" placeholder="Size">
                        <a-select-option v-for="(size, index) in SizeApi" :key="index" :value="size.id">
                          {{ size.name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="wallThickness" name="wallThickness" label="Wall Thickness">
                      <a-input-number v-model:value="formState.wallThickness" :min="0" :precision="2"
                        :style="{ width: '100%' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="insideDiameter" name="insideDiameter" label="Inside Diameter">
                      <a-input-number v-model:value="formState.insideDiameter" :min="0" :precision="2"
                        :style="{ width: '100%' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="driftDiameter" name="driftDiameter" label="Drift Diameter">
                      <a-input-number v-model:value="formState.driftDiameter" :min="0" :precision="2"
                        :style="{ width: '100%' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="drift" label="Drift" name="drift">
                      <a-select v-model:value="formState.drift" placeholder="Drift">
                        <a-select-option v-for="drift in Object.values(DriftEnum)" :key="drift" :value="drift">
                          {{ getDriftDescription(drift) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="category" label="Category" name="category">
                      <a-select v-model:value="formState.category" placeholder="Category">
                        <a-select-option v-for="category in Object.values(CategoryEnum)" :key="category"
                          :value="category">
                          {{ getCategoryDescription(category) }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <div class="sDash_form-action">
                  <sdButton v-if="localState.editionMode" v-on:click="switchUpdateOff()" class="sDash_form-action__btn"
                    weight="large" type="light">
                    Cancel
                  </sdButton>
                  <sdButton v-if="localState.editionMode" type="primary" html-type="submit" class="sDash_form-action__btn"
                    weight="large">
                    Edit
                  </sdButton>
                  <sdButton v-else type="primary" html-type="submit" class="sDash_form-action__btn" weight="large">
                    Add
                  </sdButton>
                </div>
              </a-form>
            </VerticalFormStyleWrap>
          </FormValidationWrap>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, nextTick } from "vue";
import {
  CategoryEnum, getCategoryDescription, DriftEnum,
  getDriftDescription
} from '@/common/Enums';

const columns = [
  {
    title: "Weight",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Size",
    dataIndex: "sizeName",
    key: "sizeName",
  },
  {
    title: "Wall Thickness",
    dataIndex: "weight.wallThickness",
    key: "weight.wallThickness",
  },
  {
    title: "Inside Diameter",
    dataIndex: "weight.insideDiameter",
    key: "weight.insideDiameter",
  },
  {
    title: "Drift Diameter",
    dataIndex: "weight.driftDiameter",
    key: "weight.driftDiameter",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const Weight = {
  name: "Weight",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    CardToolbox,
    SearchHeaderStyle,
  },
  setup() {
    const { state, dispatch } = useStore();
    const WeightApi = computed(() => state.WeightApi.data.data);
    const SizeApi = computed(() => state.SizeApi.data);
    const isLoading = computed(() => state.WeightApi.loading);
    const searchValue = ref("");
    const searchSizeId = ref("");
    var loading = false;
    var weightPagination = computed(() => state.WeightApi.data);
    var sortDirections = "ascend";
    const HeaderInfo = computed(() => {
      if (localState.editionMode) {
        return "Edit weight";
      }
      return "Add Weight";
    });

    const localState = reactive({
      editionMode: false,
    });
    const formRef = ref();
    const formState = reactive({
      layout: "vertical",
      name: "",
      sizeId: "",
      wallThickness: null,
      insideDiameter: null,
      driftDiameter: null,
      drift: null,
      category: null,
    });

    const rules = {
      name: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
        {
          min: 2,
          max: 100,
          message: "Field need to be longer then two and shorter then 100",
          trigger: "change",
        },
      ],
      sizeId: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("sizeInfo");
      dispatch("weightGetPagedData", pagination.value);
      localState.editionMode = false;
    });

    var pagination = computed(() => {
      return weightPagination.value && weightPagination.value.succeeded == true
        ? {
          current: weightPagination.value.pageNumber,
          total: weightPagination.value.recordsFiltered
            ? weightPagination.value.recordsFiltered
            : weightPagination.value.recordsTotal,
          pageSize: weightPagination.value.pageSize,
          OrderBy: sortDirections,
          searchValue: "",
          searchSizeId: "",
          showWeightChanger: true,
          sorter: {
            order: sortDirections,
          },
        }
        : {
          current: 1,
          pageSize: 10,
          OrderBy: sortDirections,
          searchValue: "",
          searchSizeId: "",
          sorter: {
            order: sortDirections,
          },
        };
    });
    const onHandleTableChange = (pagination) => {
      pagination = {
        ...pagination,
        sorter: {
          order: sortDirections,
        },
        current: pagination.current,
        OrderBy: sortDirections,
        searchValue: searchValue.value,
        searchSizeId: searchSizeId.value,
      };
      dispatch("weightGetPagedData", pagination);
    };
    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("weightApiDataDelete", id);
      }
      return false;
    };

    const onHandleSearch = () => {
      sortDirections = "ascend";
      pagination = {
        ...pagination,
        current: 1,
        OrderBy: sortDirections,
        pageSize: 10,
        searchValue: searchValue.value,
        searchSizeId: searchSizeId.value,
      };

      dispatch("weightGetPagedData", pagination);
    };

    const clearFilters = () => {
      searchValue.value = "";
      searchSizeId.value = "";
      onHandleSearch();
    };
    const switchUpdateOn = (weight) => {
      localState.editionMode = true;
      formState.name = weight.name;
      formState.id = weight.id;
      formState.sizeId = weight.sizeId;
      formState.wallThickness = weight.wallThickness;
      formState.insideDiameter = weight.insideDiameter;
      formState.driftDiameter = weight.driftDiameter;
      formState.drift = weight.drift;
      formState.category = weight.category;
    };
    const switchUpdateOff = () => {
      localState.editionMode = false;
      formState.name = "";
      formState.id = 0;
      formState.wallThickness = 0;
      formState.insideDiameter = 0;
      formState.driftDiameter = 0;
      formState.drift = null;
      formState.category = null;
      formState.sizeId = null;
    };

    const dataSource = computed(() =>
      WeightApi.value && WeightApi.value.length
        ? WeightApi.value.map((weight, key) => {
          const { id, name, sizeName } = weight;

          return {
            key: key + 1,
            name,
            weight,
            sizeName,
            action: (
              <div class="table-actions">
                <a-button class="edit" onClick={() => switchUpdateOn(weight)}>
                  <sdFeatherIcons type="edit" weight={14} />
                </a-button>
                &nbsp;&nbsp;&nbsp;
                <a-button class="delete" onClick={() => handleDelete(id)}>
                  <sdFeatherIcons type="trash-2" weight={14} />
                </a-button>
              </div>
            ),
          };
        })
        : []
    );

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          if (!localState.editionMode) {
            dispatch("weightSubmitData", {
              formState,
            });
          } else {
            dispatch("weightUpdateData", {
              formState,
            });
          }
          await nextTick();
          switchUpdateOff();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const customFilter = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return {
      customFilter,
      WeightApi,
      formRef,
      rules,
      onSubmit,
      isLoading,
      localState,
      handleDelete,
      switchUpdateOn,
      switchUpdateOff,
      dataSource,
      HeaderInfo,
      columns,
      onHandleSearch,
      loading,
      sortDirections,
      formState,
      searchValue,
      searchSizeId,
      weightPagination,
      SizeApi,
      onHandleTableChange,
      pagination,
      CategoryEnum,
      getCategoryDescription,
      DriftEnum,
      getDriftDescription,
      clearFilters
    };
  },
};

export default Weight;
</script>
<style lang="scss">
.sDash_form-action {
  display: flex;
  justify-content: space-between;
}

td:not(:first-child) {
  text-align: center;
}

.ant-btn-lg {
  height: 38px;
  width: 100%;
}

.button-container {
  place-self: center;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  height: 40px;
}
</style>
