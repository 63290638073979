// Enums.js
export const CategoryEnum = {
    NonUpsetTubing: 1,
    ExternalUpsetTubing: 2,
    IntegralTubing: 3,
    ButtressCasing: 4,
};

export function getCategoryDescription(category) {
    switch (category) {
        case CategoryEnum.NonUpsetTubing:
            return "Non Upset Tubing";
        case CategoryEnum.ExternalUpsetTubing:
            return "External Upset Tubing";
        case CategoryEnum.IntegralTubing:
            return "Integral Tubing";
        case CategoryEnum.ButtressCasing:
            return "Buttress Casing";
        default:
            return "Unknown Category";
    }
}

export const DriftEnum = {
    Standard: 1,
    Special: 2,
};
export function getDriftDescription(drift) {
    switch (drift) {
        case DriftEnum.Standard:
            return "Standard";
        case DriftEnum.Special:
            return "Special";
        default:
            return "Unknown Drift";
    }
}